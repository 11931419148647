import { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import i18n from './i18n/configs';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Business from './pages/Business';
import News from './pages/News';
import NewsDetail from './pages/NewsDetail';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';

const LanguageWrapper = () => {
  const { lang } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  }, [location]);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="aboutus" element={<AboutUs />} />
      <Route path="business" element={<Business />} />
      <Route path="news" element={<News />} />
      <Route path="news/:slug" element={<NewsDetail />} />
      <Route path="contactus" element={<ContactUs />} />
      <Route path="privacypolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/:lang/*" element={<LanguageWrapper />} />
      <Route path="/" element={<Navigate replace to="/ja" />} />
    </Routes>
  );
}

export default App;
