import React, { useState, useEffect, useRef } from 'react';

import { styled, createTheme } from '@mui/material/styles';
import { Container, Box, Typography, Fade } from '@mui/material';

interface IntroHeaderProps {
    backgroundImage: string;
  }
  
export const theme = createTheme({
    palette: {
      primary: {
        main: '#0B76A0',
      },
      secondary: {
        main: '#1BA1E2',
        light: '#B7E1F7',
      },
    },
    typography: {
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: '2.5rem',
        '@media (min-width:600px)': {
          fontSize: '4rem',
        },
      },
    },
  });
  
  export const ResponsiveImage = styled('img')({
    maxWidth: '100%',
    height: 'auto',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  });
  
  export const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(8, 0),
  }));
  
  export const IntroHeader = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'backgroundImage',
  })<IntroHeaderProps>(({ theme, backgroundImage }) => ({
    background: `url(${backgroundImage}) center / cover no-repeat`,
    width: '100%',
    textAlign: 'center',
    color: '#fff',
    padding: theme.spacing(10, 2),
  }));
  
  export const HeadingJumbo = styled(Typography)({
    fontSize: '3rem',
    fontWeight: 600,
    margin: '20px 0',
  });
  

  interface ParallaxBoxProps {
    image: string;
    overlayColor?: string;
    children: React.ReactNode;
    paddingTop?: number;
    paddingBottom?: number;
  }
  
  export const ParallaxBox: React.FC<ParallaxBoxProps> = ({
          image,
          overlayColor = 'rgba(0,0,0,0)',
          children,
          paddingTop = 2,
          paddingBottom = 2,
      }) => {
    return (
      <div style={{
        position: 'relative',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        zIndex: 0,
        paddingTop: `${paddingTop}px`,
        paddingBottom: `${paddingBottom}px`,
    }}>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: overlayColor,
          zIndex: 1,
          }} />
        <div style={{ position: 'relative', zIndex: 2 }}>
          {children}
        </div>
      </div>
    );
  };

  
  export const FadeInWhenVisible: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      if (domRef.current) {
        observer.observe(domRef.current);
      }
      return () => observer.disconnect();
    }, []);
  
    return (
      <div ref={domRef}>
        <Fade in={isVisible} timeout={1000}>
          <div>{children}</div>
        </Fade>
      </div>
    );
  };
