import React from 'react';
import { Box, Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { theme } from './components/styles';
import NavigationBar from './NavigationBar';
import ContactForm from './ContactForm';
import Footer from './Footer';


const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const pageTitle = t('Privacy Policy - Microscope for Lab Automation｜embrio');
  const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
  const siteUrl = 'https://www.embrio.dev/news:slug';

  return (
    <ThemeProvider theme={theme}>

      <Helmet>
        <link rel="canonical" href="https://www.embrio.dev/" />

        {/* Basic SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" href="/images/embrio_logo_square.png" />
        {/* <link rel="icon" type="image/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={imageUrl} /> */}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
        {/* <meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>

      <NavigationBar />

      <Container maxWidth="md">
        <Box sx={{ py: 6 }}>
          <h2>embrio LLC. Privacy Policy</h2>
          <p>Last updated: 2024-06-25</p>

          <p>embrio LLC. (hereinafter referred to as “the Company”) hereby establishes the following Privacy Policy (hereinafter referred to as “this Policy”) as fundamental matters to be observed for the proper management and operation of personal information held by the Company in connection with the use of products and services provided by the Company (hereinafter referred to as “the Service”) by users (hereinafter referred to as “Users”). The Company will handle personal information appropriately according to this Policy.</p>

          <h3>1. Acquisition of Personal Information</h3>
          <p>In this Policy, “personal information” refers to personal information as defined by the Personal Information Protection Law, which includes information that can identify specific individuals by names, dates of birth, or other descriptions contained within the information (including information that can easily be matched with other information, thereby identifying specific individuals). The Company will acquire personal information only for legitimate business purposes clearly defined in advance, and within the scope necessary to achieve those purposes, after obtaining the consent of the individual in a lawful and fair manner.</p>

          <h3>2. Purpose of Use of Personal Information</h3>
          <p>The Company will use personal information obtained from Users only within the scope of the following purposes. If it is necessary to use personal information beyond these purposes, consent will be obtained from the individual beforehand. The Company may change the purposes of use within a range reasonably deemed relevant and will promptly notify or announce to Users if there are any changes:</p>
          <ul>
          <li>To provide the Service</li>
          <li>To improve the convenience of the Service</li>
          <li>To create statistical data related to the use of the Service and conduct marketing</li>
          <li>To conduct surveys on current or newly considered services</li>
          <li>To consider and provide new plans related to the Service</li>
          <li>To refuse service to those who violate the terms of service or attempt to use the Service for fraudulent or inappropriate purposes</li>
          <li>To respond to inquiries and verify identities</li>
          <li>For the drawing of lotteries and the shipment of prizes and products in campaigns</li>
          <li>To provide information (including information about new services or products offered by the Company, or new services or products recommended by other companies) and send newsletters</li>
          <li>For other necessary communications related to the use of the Service</li>
          <li>To exercise rights or fulfill obligations under contracts or law</li>
          </ul>

          <h3>3. Disclosure of Personal Information</h3>
          <p>The Company will not disclose or provide personal information to third parties without the consent of the individual, except as permitted under the Personal Information Protection Law and other relevant laws. However, personal information may be disclosed or provided to third parties in the following cases:</p>
          <ul>
          <li>With the consent of the individual</li>
          <li>When requested by public institutions such as courts or police, or their delegates</li>
          <li>When it is deemed necessary to protect the rights, property, or services of third parties or the Company in relation to the use of the Service, in violation of laws or service terms</li>
          <li>When there is an imminent danger to life, body, or property and it is difficult to obtain the individual’s consent</li>
          <li>In the event of business succession due to mergers, company splits, business transfers, or other reasons</li>
          </ul>

          <h3>4. Access, Correction, and Deletion of Personal Information</h3>
          <p>The Company will comply with requests from individuals for disclosure, correction, suspension of use, or deletion of personal information, after verifying the identity of the individual, through the contact point listed at the end of this document. However, the Company may refuse requests that are unreasonably repetitive, require excessive technical effort, or risk the privacy of others.</p>

          <h3>5. Cookies and IP Address Information</h3>
          <p>When Users access the Service, the Company saves and references cookies and automatically collects information such as IP addresses, viewed advertisements and pages, and the usage environment, which are recorded on the server. This information is used to improve the Service, as planning material for new services, and to analyze and provide effective advertisements and information to advertisers and information providers. This analysis does not include personally identifiable information.</p>

          <h3>6. Access Log Information</h3>
          <p>The Company uses Google Analytics to collect and analyze access logs (history) of Users using the Service. Google Analytics collects logs without including personally identifiable information using cookies. These access logs are used to improve the usability of the Service, as planning material for new services, and for server operation, statistical fault analysis, and site marketing. The collected logs are managed according to Google’s privacy policy. Please refer to Google’s privacy policy for more information.</p>

          <h3>7. Security Management Measures</h3>
          <p>The Company takes necessary and appropriate measures for the security management of personal information, including educating and training all employees, including executives, implementing access restrictions to personal information files, recording access logs, and taking security measures to prevent unauthorized access from outside to protect personal information from leakage, loss, or damage.</p>

          <h3>8. Scope of the Privacy Policy</h3>
          <p>This Policy does not apply to websites or applications linked within the websites operated by the Company’s business partners, subcontractors, or the Company. The Company is not responsible for any issues that arise on these sites. Please check the handling of personal information on each site.</p>

          <h3>9. Revision of the Privacy Policy</h3>
          <p>The Company continuously reviews and improves the handling of personal information and may revise this Policy as necessary. Changes to the Policy will be notified to Users either individually or announced within the Service.</p>

          <h3>10. Contact Information</h3>
          <p>For inquiries or complaints regarding the handling of personal information by the Company, please contact us from the Contact Us form.</p>

        </Box>
      </Container>

      <ContactForm />
      <Footer />
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
