import React, { useEffect, useState } from 'react';
import { Typography, Box, Container, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

import { IntroHeader, HeadingJumbo, theme } from './components/styles';
import api from './components/api';

import NavigationBar from './NavigationBar';
import ContactForm from './ContactForm';
import Footer from './Footer';

import topImage from './images/news.jpg';

interface ArticleData {
  title: string;
  date: string;
  slug: string;
  content: string;
}

const NewsList: React.FC = () => {
  const { t, i18n } = useTranslation();

  const pageTitle = t('News - Microscope for Lab Automation｜embrio');
  const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
  const siteUrl = 'https://www.embrio.dev/news';

  const [articles, setArticles] = useState<ArticleData[]>([]);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get(`/api/news?lang=${i18n.language}`);
        if (Array.isArray(response.data)) {
          setArticles(response.data);
        } else {
          console.error("API response is not an array:", response.data);
        }
      } catch (error) {
        console.error("Fetching articles failed", error);
      }
    };

    fetchArticles();
  }, [i18n.language]);

  return (
    <ThemeProvider theme={theme}>

      <Helmet>
        <link rel="canonical" href="https://www.embrio.dev/" />

        {/* Basic SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" href="/images/embrio_logo_square.png" />
        {/* <link rel="icon" type="image/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={imageUrl} /> */}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
        {/* <meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>

      <NavigationBar />
      <IntroHeader backgroundImage={topImage}>
        <Box sx={{ py: 6 }}>
          <HeadingJumbo>News</HeadingJumbo>
        </Box>
      </IntroHeader>
      <Container maxWidth="md">
        <Box
          my={5}
          sx={{
            width: matches ? '100%' : '80%',
            mx: 'auto',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
            Corporate News
          </Typography>
          {articles.map(article => (
            <Box key={article.slug} sx={{ mb: 3 }}>
              <Typography
                variant="h5"
                component={RouterLink}
                to={`/${i18n.language}/news/${article.slug}`}
                sx={{
                  textDecoration: 'none',
                  color: theme.palette.secondary.main,
                  ':hover': { textDecoration: 'underline' },
                }}
              >
                {article.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                {new Date(article.date).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {article.content ? (i18n.language === 'en' ? article.content.split(' ').slice(0, 20).join(' ') + '...' : article.content.slice(0, 50) + '...') : 'Read more...'}{' '}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>

      <ContactForm />
      <Footer />
    </ThemeProvider>
  );
};

export default NewsList;
