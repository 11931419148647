import React, { useState, ChangeEvent, FormEvent } from 'react';
import api from './components/api';
import { Box, TextField, Button, Typography, Container, Grid, Alert } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { IntroHeader, HeadingJumbo, theme } from './components/styles';
import NavigationBar from './NavigationBar';
import Footer from './Footer';

import topImage from './images/contact.jpg';

interface FormData {
    name: string;
    company: string;
    email: string;
    phone: string;
    message: string;
}

const ContactForm: React.FC = () => {
    const { t } = useTranslation();

    const pageTitle = t('Contact Us - Microscope for Lab Automation｜embrio');
    const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
    const siteUrl = 'https://www.embrio.dev/contactus';

    const initialFormData: FormData = {
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
    };

    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle');
    const [errors, setErrors] = useState<Partial<FormData>>({});

    const validateForm = () => {
        const newErrors: Partial<FormData> = {};
        if (!formData.name) newErrors.name = t('Name is required');
        if (!formData.company) newErrors.company = t('Company is required');
        if (!formData.email) newErrors.email = t('Email is required');
        if (!formData.phone) newErrors.phone = t('Phone is required');
        if (!formData.message) newErrors.message = t('Message is required');
        return newErrors;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setSubmissionStatus('loading');
        try {
            const response = await api.post('/submit-form', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                setFormData(initialFormData);
                setSubmissionStatus('success');
            } else {
                setSubmissionStatus('error');
            }
        } catch (error) {
            setSubmissionStatus('error');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <link rel="canonical" href="https://www.embrio.dev/" />

                {/* Basic SEO */}
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="icon" href="/images/embrio_logo_square.png" />
                {/* <link rel="icon" type="image/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={siteUrl} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                {/* <meta property="og:image" content={imageUrl} /> */}

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={siteUrl} />
                <meta property="twitter:title" content={pageTitle} />
                <meta property="twitter:description" content={pageDescription} />
                {/* <meta property="twitter:image" content={imageUrl} /> */}
            </Helmet>

            <NavigationBar />
            <Box sx={{ overflow: 'hidden', bgcolor: 'background.default', py: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IntroHeader backgroundImage={topImage}>
                    <Box sx={{ py: 6 }}>
                        <HeadingJumbo>
                            Contact Us
                        </HeadingJumbo>
                    </Box>
                </IntroHeader>

                <Container maxWidth="md">
                    <Box my={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {t('Contact Us')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
                                    {t('For inquiries or requests, please feel free to contact us using the form below.')}<br />{t('We will get back to you shortly.')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                        {submissionStatus === 'success' ? (
                            <Alert severity="success">{t('Thank you, The message is sent successfully.')}</Alert>
                        ) : (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" textAlign="right" sx={{ color: theme.palette.text.secondary }}>
                                            {t('* Required.')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            label='Name' 
                                            name="name" 
                                            value={formData.name} 
                                            onChange={handleChange} 
                                            required 
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            label='Company' 
                                            name="company" 
                                            value={formData.company} 
                                            onChange={handleChange} 
                                            required 
                                            error={!!errors.company}
                                            helperText={errors.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth 
                                            label='Email' 
                                            name="email" 
                                            type="email" 
                                            value={formData.email} 
                                            onChange={handleChange} 
                                            required 
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth 
                                            label='Phone' 
                                            name="phone" 
                                            type="tel" 
                                            value={formData.phone} 
                                            onChange={handleChange} 
                                            required 
                                            error={!!errors.phone}
                                            helperText={errors.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth 
                                            label='Message' 
                                            name="message" 
                                            multiline 
                                            rows={4} 
                                            value={formData.message} 
                                            onChange={handleChange} 
                                            required 
                                            error={!!errors.message}
                                            helperText={errors.message}
                                        />
                                    </Grid>
                                    {submissionStatus === 'error' && (
                                        <Alert severity="error" sx={{ mt: 2 }}>
                                            {t('There is an error sending the form. Please check the email address and phone number. If the problem persists, please contact contact@embrio.dev instead.')}
                                        </Alert>
                                    )}
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary" disabled={submissionStatus === 'loading'}>
                                            {submissionStatus === 'loading' ? t('Sending...') : t('Send')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Box>
                </Container>
            </Box>

            <Footer />

        </ThemeProvider>
    );
};

export default ContactForm;
