import { ThemeProvider } from '@mui/material/styles';
import { Container, Grid, Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next'

import { theme } from './components/styles';

const Footer = () => {
  const { i18n } = useTranslation();

  const generatePath = (path: string) => `/${i18n.language}${path}`;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5 }}>
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body1">
                © 2024 embrio LLC. All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <Link href={generatePath("/privacypolicy")} color="inherit" underline="none">
                  Privacy Policy
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
