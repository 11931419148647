import axios from 'axios';

// Determine the API URL based on the environment
// const baseURL = 'http://127.0.0.1:8080';
const baseURL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: baseURL,
});

export default api;
