import { Typography, Grid, Box, Paper } from '@mui/material';
import { IntroHeader, HeadingJumbo, theme } from './components/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

import NavigationBar from './NavigationBar';
import ContactForm from './ContactForm';
import Footer from './Footer';

import topImage from './images/aboutus.jpg';

const CompanyInfoItem = ({ title, content }: { title: string, content: string }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      <Typography variant="subtitle1" component="h3" sx={{ fontWeight: 'bold', mb: 1, color: theme.palette.secondary.main }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {content}
      </Typography>
    </Box>
  </Grid>
);

const AboutUs = () => {
  const { t } = useTranslation();

  const pageTitle = t('About Us - Microscope for Lab Automation｜embrio');
  const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
  const siteUrl = 'https://www.embrio.dev/aboutus';


  return (
    <ThemeProvider theme={theme}>

      <Helmet>
        <link rel="canonical" href="https://www.embrio.dev/" />

        {/* Basic SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" href="/images/embrio_logo_square.png" />
        {/* <link rel="icon" type="image/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={imageUrl} /> */}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
      </Helmet>


      <NavigationBar />

      <Box sx={{ overflow: 'hidden', bgcolor: 'background.default', py: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IntroHeader backgroundImage={topImage}>
          <Box sx={{ py: 6 }}>
        <HeadingJumbo>
          About Us
        </HeadingJumbo>
          </Box>
        </IntroHeader>

        <Box sx={{ my: 4, textAlign: 'center', maxWidth: '90%' }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
            {t('Automate Your Lab with 3 Lines of Code')}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
            {t('Developing Web API-Controllable Experimental Equipment and Lab Automation Software')}
          </Typography>
        </Box>

        <Box sx={{ bgcolor: 'background.paper', p: 4, borderRadius: 2, my: 4, width: '75%', boxShadow: 'none', fontStyle: 'italic', color: 'gray' }}>
          <Typography paragraph sx={{ textAlign: 'justify' }}>
            {t('The need for lab automation is increasing with the advancement of research, especially in research institutions and pharmaceutical companies, where automation of experimental processes requiring fine operations and high precision is becoming increasingly important.')}<br />{t('However, handling delicate samples and following precise experimental procedures involve advanced techniques, delicate operations, and complex processes that place a significant burden on researchers, hindering the progress of their research.')}<br />{t('While automation is the key to efficient research progress and accurate data acquisition, it is not easy for research institutions to introduce and operate a precise and flexible automation system. This is because lab automation demands accurate handling of delicate samples, precise measurements, the realization of continuous experimental processes, and adaptability to a variety of protocols.')}<br />{t('By developing hardwares and softwares for lab automation, we support research institutions in achieving efficient and high-precision lab automation.')}
          </Typography>
        </Box>

        <Box sx={{ py: 4, width: '90%' }}>
          <Typography variant="h4" component="h2" gutterBottom textAlign="center" sx={{ fontWeight: 600 }}>
            {t('Company Overview')}
          </Typography>
          <Paper elevation={0} sx={{ p: 3, mx: 'auto', maxWidth: 960, borderRadius: 2, boxShadow: '0px 8px 24px rgba(0,0,0,0.12)' }}>
            <Grid container spacing={2} justifyContent="center">
              <CompanyInfoItem title={t('Company Name')} content={t('embrio LLC.')} />
              <CompanyInfoItem title={t('Establishment')} content={t('July 2021')} />
              <CompanyInfoItem title={t('Address')} content={t('4, Minamishinozakimachi, Edogawa-ku, Tokyo 133-0065')} />
              <CompanyInfoItem title={t('Business Area')} content={t('Development and sales of laboratory automation systems')} />
            </Grid>
          </Paper>
        </Box>
      </Box>

      <ContactForm />
      <Footer />

    </ThemeProvider>
  );
};

export default AboutUs;
