import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import logo from './images/embrio_logo_wide_1200-630_white_cropped.png';
import LanguageIcon from '@mui/icons-material/Language';

const LogoImage = styled('img')({
  width: '108px',
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: '#fff',
  marginLeft: '20px',
  '&:hover': {
    textDecoration: 'none',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
    padding: theme.spacing(1, 0),
  },
}));

const NavigationBar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLanguageMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    const pathParts = location.pathname.split('/');
    pathParts[1] = language; 
    const newPath = pathParts.join('/');
    navigate(newPath);
    setAnchorEl(null);
    handleCloseMobileMenu();
  };

  const generatePath = (path: string) => `/${i18n.language}${path}`;

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleCloseMobileMenu = () => {
    setMobileMoreAnchorEl(null);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleCloseMobileMenu}
    >
      <MenuItem onClick={handleCloseMobileMenu}>
        <StyledLink to={generatePath("/")}>
          <Button color="primary">HOME</Button>
        </StyledLink>
      </MenuItem>
      <MenuItem onClick={handleCloseMobileMenu}>
        <StyledLink to={generatePath("/aboutus")}>
          <Button color="primary">ABOUT</Button>
        </StyledLink>
      </MenuItem>
      <MenuItem onClick={handleCloseMobileMenu}>
        <StyledLink to={generatePath("/business")}>
          <Button color="primary">BUSINESS</Button>
        </StyledLink>
      </MenuItem>
      <MenuItem onClick={handleCloseMobileMenu}>
        <StyledLink to={generatePath("/news")}>
          <Button color="primary">NEWS</Button>
        </StyledLink>
      </MenuItem>
      <MenuItem onClick={handleCloseMobileMenu}>
        <StyledLink to={generatePath("/contactus")}>
          <Button color="primary">CONTACT</Button>
        </StyledLink>
      </MenuItem>
    </Menu>
  );

  return (
    <StyledAppBar position="static" elevation={0}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleMobileMenuOpen}
          sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
        >
          <MenuIcon style={{ color: '#fff' }} />
        </IconButton>
        <LogoImage src={logo} alt="embrio logo" />
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
          <StyledLink to={generatePath("/")}>
            <Button color="inherit">HOME</Button>
          </StyledLink>
          <StyledLink to={generatePath("/aboutus")}>
            <Button color="inherit">ABOUT</Button>
          </StyledLink>
          <StyledLink to={generatePath("/business")}>
            <Button color="inherit">BUSINESS</Button>
          </StyledLink>
          <StyledLink to={generatePath("/news")}>
            <Button color="inherit">NEWS</Button>
          </StyledLink>
          <StyledLink to={generatePath("/contactus")}>
            <Button color="inherit">CONTACT</Button>
          </StyledLink>
        </Box>
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label={t('language')}
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleLanguageMenuClick}
          sx={{ marginLeft: 'auto' }}
        >
          <LanguageIcon style={{ color: '#fff' }} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleLanguageChange('en')}>EN</MenuItem>
          <MenuItem onClick={() => handleLanguageChange('ja')}>JA</MenuItem>
          <MenuItem onClick={() => handleLanguageChange('zh')}>ZH</MenuItem>
        </Menu>
      </Toolbar>
      {renderMobileMenu}
    </StyledAppBar>
  );
};

export default NavigationBar;
