import { ThemeProvider } from '@mui/material/styles';
import { Button, Container, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'


import { theme, FadeInWhenVisible } from './components/styles';

const ContactForm = () => {
  const { t, i18n } = useTranslation();

  const generatePath = (path: string) => `/${i18n.language}${path}`;

  return (
    <ThemeProvider theme={theme}>

      <FadeInWhenVisible>
        <Box sx={{ bgcolor: 'grey.100', py: 8, textAlign: 'center' }}>
          <Container maxWidth="sm">
            <Typography variant="h4" component="h2" gutterBottom>
              {t('Get in Touch')}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {t('For inquiries or requests for product catalogs, feel free to reach out.')}
            </Typography>
            <Button variant="outlined" color="primary" href={generatePath("/contactus")} size="large">
              {t('Contact Us')}
            </Button>
          </Container>
        </Box>
      </FadeInWhenVisible>

    </ThemeProvider>
  );
};

export default ContactForm;
