import React from 'react';
import {
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  useTheme,
  Container,
} from '@mui/material';
import { StyledContainer, theme, IntroHeader, HeadingJumbo } from './components/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import NavigationBar from './NavigationBar';
import Footer from './Footer';

import frontRenderImage from './images/scope-handler.png';
// import frontRenderImage from './images/frontrender.png';
import topImage from './images/products.jpg';
import robotImage from './images/robot.svg';
import dataImage from './images/data.svg';
import microscopeImage from './images/microscope.svg';
import emailImage from './images/email.png';
import cyclesRenderImage from './images/Cycles_render-1.png';
import ContactForm from './ContactForm';

interface SectionProps {
  title: string;
  content: string;
  imageUrl: string;
}

const Section = ({ title, content, imageUrl }: SectionProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      sx={{ overflow: 'auto', backgroundColor: '#f5f5f5', padding: theme.spacing(6) }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={9} md={10} lg={10}>
        <Typography paragraph sx={{ whiteSpace: 'pre-line' }}>
          {content}
        </Typography>
      </Grid>
      <Grid item xs={3} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={imageUrl} alt={title} style={{ maxWidth: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

const specifications = [
  { key: 'Objective Lens', value: '×4-40' },
  { key: 'Observation Methods', value: 'Brightfield observation' },
  { key: 'Fixture Payload', value: '500g' },
  { key: 'External Dimensions', value: 'W: 355mm, L: 305mm, H: 355mm' },
  { key: 'Weight', value: '18kg' },
  {
    key: 'Control Methods',
    value: 'CLI: Web API, Python API, ROS\nGUI: Stage movement, streaming, etc.',
  },
];


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  borderRadius: 5,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: 5,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));


const Business = () => {
  const { t } = useTranslation();

  const pageTitle = t('Business - Microscope for Lab Automation｜embrio');
  const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
  const siteUrl = 'https://www.embrio.dev/business';
  
  return (
    <ThemeProvider theme={theme}>

      <Helmet>
        <link rel="canonical" href="https://www.embrio.dev/" />

        {/* Basic SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" href="/images/embrio_logo_square.png" />
        {/* <link rel="icon" type="im</Button>age/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={imageUrl} /> */}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
        {/* <meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>

      <NavigationBar />
      <Box sx={{ overflow: 'hidden', bgcolor: 'background.default', py: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IntroHeader backgroundImage={topImage}>
          <Box sx={{ py: 6 }}>
            <HeadingJumbo>Business</HeadingJumbo>
          </Box>
        </IntroHeader>
      </Box>

      <StyledContainer maxWidth="md" style={{ maxWidth: '90%', height: 'auto' }}>
        
        <Box textAlign="left" my={2} sx={{ mb : 10 }}>
          <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
            {t('Hardware Development for Lab Automation')}
          </Typography>
          <Grid container spacing={2} my={2} alignItems="center" justifyContent="center" sx={{ mb : 2}}>
            <Grid item xs={12} md={6}>
              <Box textAlign="left" my={4}>
                <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                  {t('We support research in biology and materials science with API-controllable experimental equipment.')}<br />{t('Our Web API-controlled microscope, ‘OpenScope’, enables you to automate observation processes with just a few lines of code, enhancing and streamlining your experiments. It also allows for easy implementation of data management, AI-driven analysis of observation results, and system integration, enabling researchers to automate various protocols and seamlessly integrate with existing automation systems.')}<br />{t('The plate handler ‘OpenHandler’ automates sample transfer between experimental devices through simple teaching and straightforward coding. It can be used in combination with OpenScope for automated observations or integrated into various automation systems.')}<br />{t('Additionally, these experimental devices can be customized to meet your specific requirements, so please feel free to contact us for a consultation.')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={frontRenderImage} alt="Front Render of OpenScope" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
          </Grid>

          <Accordion>
            <AccordionSummary>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('OpenScope Details')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container sx={{ padding: theme.spacing(4, 0) }}>
                <Section
                  title={t('Easy Robot Integration')}
                  content={t('On the hardware side, our design, optimized for access via robotic arms and collaboration with dispensers, allows for flexible integration.\nOn the software side, there are no limits to system integration since it can be controlled with Web API / ROS. This makes it possible for it to operate in collaboration with the entire experimental line through the management system and to operate based on inputs from robots. With the Web API, control is done via the HTTP protocol, so by writing just a few lines of code in any programming language, you can execute actions such as stage movement and capturing images.')}
                  imageUrl={robotImage}
                />
              </Container>

              <Container sx={{ padding: theme.spacing(4, 0) }}>
                <Section
                  title={t('Seamless Data Management')}
                  content={t('It is possible to extract data through communication without the need for a USB drive. As a result, post-analysis with any AI model and storage in databases can be executed seamlessly, achieving end-to-end automation.\nTherefore, operations such as analyzing the captured results in real-time to determine the next protocol can be achieved with just a few lines of code.')}
                  imageUrl={dataImage}
                />
              </Container>

              <Container sx={{ padding: theme.spacing(4, 0) }}>
                <Section
                  title={t('Compatible with various experimental protocols')}
                  content={t('Both hardware and software can be easily customized, such as invoking image recognition models or designing container holders, to suit the purpose. It is compatible with integration into any lab automation system.\n\nUse cases include:\n- Building lab automation systems combined with robotic arms and automatic dispensers\n- Time-lapse observation of cell and organoid cultures\n- Slide scanner\n- Observation of microfluidic devices')}
                  imageUrl={microscopeImage}
                />
              </Container>

              <Box textAlign="center" my={4}>
                <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
                  {t("Basic Specifications")}
                </Typography>
                <Grid container spacing={4} alignItems="center" justifyContent="center">
                  <Grid item xs={12} md={8}>
                    <Paper
                      sx={{
                        mb: 4,
                        overflow: 'hidden',
                        boxShadow: theme.shadows[3],
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      <Table sx={{ minWidth: 300 }}>
                        <TableBody>
                          {specifications.map((spec, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                backgroundColor: index % 2 ? 'transparent' : theme.palette.action.hover,
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  fontWeight: 'bold',
                                  color: theme.palette.secondary.main,
                                  borderBottom: `1px solid ${theme.palette.divider}`,
                                }}
                              >
                                {t(spec.key)}
                              </TableCell>
                              <TableCell align="left" sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
                                {t(spec.value)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <img
                        src={cyclesRenderImage}
                        alt="Product"
                        style={{ maxWidth: '100%', height: 'auto', borderRadius: theme.shape.borderRadius }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('OpenHandler Details')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left" my={4}>
                <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                  {t('To be released soon...')}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box textAlign="left" my={2} sx={{ mb : 10 }}>
          <Typography variant="h4" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
            {t('Software Development for Lab Automation')}
          </Typography>
          <Box textAlign="left" my={4}>
            <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
              {t('We begin by understanding your specific needs, then proceed to develop the optimal image analysis models, design automation protocols, and create control programs for automated equipment.')}<br />{t('Our experience includes developing image analysis models for cell morphology evaluation, feature extraction, cell counting, confluency analysis, and microbial behavior tracking. We’ve also created control software that integrates multiple experimental devices and existing systems to automate experiments, and developed applications that visually present analysis results.')}<br />{t('We cater to the diverse automation needs of companies and researchers, ensuring sustainable automation environments by providing solutions that are easy to maintain and update.')}
            </Typography>
          </Box> 
        </Box>

        <Box textAlign="left" my={2} sx={{ mb : 10 }}>
          <Typography variant="h4" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
            {t('Smartification of Experimental Equipment')}
          </Typography>
          <Box textAlign="left" my={4}>
            <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
              {t('We assist manufacturers in making their experimental equipment smart and IoT-enabled.')}<br />{t('- Support for the development of APIs, opening control functions, and software design necessary for smartification during the development of new products or version upgrades of existing products')}<br />{t('- Retrofit devices that IoT-enable products without altering existing designs')}<br /><br />{t('These enable automated experimentation, inter-device connectivity, and seamless data acquisition, thus meeting the expanding needs of users as lab automation becomes more widespread. Manufacturers interested in smartifying various meters and experimental devices such as scales, pH meters, centrifuges, and incubators are encouraged to contact us freely. Our engineers will provide detailed explanations.')}
            </Typography>
          </Box> 
        </Box>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ overflow: 'auto', padding: theme.spacing(2) }}
        >
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={emailImage} alt="email" style={{ maxWidth: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={10} md={10} lg={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5">
              {t("We also accept consultations for commissioned development and requests for product demos.")}<br />{t("Please contact us via the inquiry form.")}
            </Typography>
          </Grid>
        </Grid>

      </StyledContainer>
      <ContactForm />
      <Footer />
    </ThemeProvider>
  );
};

export default Business;
